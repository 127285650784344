<script
    lang="ts"
    setup
>

interface Props {
    name?: string;
    preIcon?: string;
    icon?: string;
    disabled?: boolean;
    link?: string;
    bordered?: boolean;
    loading?: boolean;
    valid?: boolean;
    title?: string;
    type?: 'button' | 'submit' | 'reset';
}

const props = withDefaults(defineProps<Props>(), {
    type: 'button'
});

function handdleClick() {
    if (props.disabled) return;
    emit('click');
}

const emit = defineEmits(["click"])
</script>
<template>
    <NuxtLink
        class="buttonLinear text-base"
        v-if="link"
        :class="[{isDisabled: disabled}, {'btn-border-linear': bordered}, {'btn-linear': !bordered}]"
        :title="title"
        :to="!disabled ? link : ''"
        @click="handdleClick"
    >
        <ElementsIcon
            v-if="preIcon && !loading && !valid"
            :icon="preIcon"
        />
        <ElementsIcon
            class="infiniteRotateRight"
            v-else-if="loading && !valid"
            icon="regular-rotate-right"
        />
        <ElementsIcon
            v-else-if="!loading && valid"
            icon="check-solid"
        />
        <span v-if="name">{{ name }}</span>
        <ElementsIcon
            v-if="icon"
            :icon="icon"
        />
    </NuxtLink>
    <button
        class="buttonLinear text-base"
        v-else
        :class="[{isDisabled: disabled}, {'btn-border-linear': bordered}, {'btn-linear': !bordered}]"
        :disabled="disabled"
        :title="title"
        :type="type"
        @click="handdleClick"
    >
        <ElementsIcon
            v-if="preIcon && !loading && !valid"
            :icon="preIcon"
        />
        <ElementsIcon
            class="infiniteRotateRight"
            v-else-if="loading && !valid"
            icon="regular-rotate-right"
        />
        <ElementsIcon
            v-else-if="!loading && valid"
            icon="check-solid"
        />
        <span v-if="name">{{ name }}</span>
        <ElementsIcon
            v-if="icon"
            :icon="icon"
        />
    </button>
</template>

<style scoped>
.buttonLinear {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.infiniteRotateRight {
    animation: rotateRight 2s infinite;
}

@keyframes rotateRight {

    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}
</style>